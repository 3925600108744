import React from 'react';

const ExtLink = ({ children, props, link, title, classTag, target }) =>{

    const links = link;
    const titles = title;
    const className = classTag;
    const targets = target;

    return(
        <>
            <a href={links} title={titles} className={className} target={`${() => (targets) ? '_blank' : '_self' }`} rel="noopener noreferrer">
            {children}
            </a>
        </>
    )
}

export default ExtLink;


