import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

import ExtLink from '../components/extLink';



const Marketing = (props) =>{

    return(
        <>

            <Meta
                title="デジタルマーケティング事業"
                description="新規、既存の顧客に対する販売支援、営業支援、イベント支援、応援支援などデジタル技術、「メディア」を使用して総合的に御社をサポートします。"
            />

            <Contents>

                <div className="c-sub-header">
                    <em className="c-sub-header--eng u-josef">
                    MARKETING
                    </em>

                    <h1 className="c-sub-header--title">
                    デジタルマーケティング事業
                    </h1>

                    <p className="c-sub-header--sup">
                    新規、既存の顧客に対する販売支援、営業支援、イベント支援、応援支援など<br />
                    デジタル技術、「メディア」を使用して総合的に御社をサポートします。
                    </p>
                </div>



                <div className="p-solution">

                    <section className="p-solution-box">
                        <div className="p-solution-box-inner">

                            <div className="p-solution-box-cnt is-marketing">
                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--text">
                                    メディア事業
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    デジタルマーケティングにおける「メディア」とは、オンライン上にアーカイブされる「記事」や「コンテンツ」のことであり、「記事」を効率的且つ定期的に発信していくことで、お客様（取引先・得意先）や消費者の目に留まる機会に恵まれる他、デジタルアーカイブは削除するまで永久的にオンライン上に保存公開されていきます。その特性を活かし、御社の販売支援に寄与できるだけではなく、販促ソリューション事業とDXツール利用の相乗効果が期待でき、総合的に御社をサポートすることが可能となります。
                                    </p>
                                </div>
                            </div>

                        </div>
                    </section>

                    <div className="p-solution-list is-sub">
                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    記事・コンテンツ制作
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/content_create_support.png"
                                    alt="記事・コンテンツ制作"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    SEO記事に限らずインタビュー・オリジナル記事・著名人連載・ニュース記事など様々なタイプのコンテンツ制作を承っております。テーマ策定/キーワード選定・画像制作・CMS入稿・専門家監修・構成案作成等の記事制作に関わる業務はすべて対応しており、ご状況に応じた最適なソリューションの提案が可能です。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    オウンドメディア立ち上げ・運用
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/owned_media_support.png"
                                    alt="オウンドメディア立ち上げ・運用"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    オウンドメディアの立ち上げ・運用をサポートいたします。新規顧客の獲得や既存顧客の育成、採用など目的に応じたオウンドメディアを作成し、立ち上げ後の戦略立案を含めた運用コンサルティングも承っております。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    Web制作
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/web_create_support.png"
                                    alt="Web制作"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    ホームページ・ランディングページの制作をメインにWeb制作サービスを展開しております。デザイン・コーディングのみの部分的な発注はもちろんのこと、企画からの丸投げ発注にも対応可能です。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    SEOコンサルティング
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/seo_support.png"
                                    alt="SEOコンサルティング"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    レポーティング・リライト提案・新規記事制作などを実施し、新規顧客の獲得に特化したメディアサイトのコンサルティングが可能です。メディア運用実績の豊富なディレクターの派遣にも対応しており、伴走型のサポート体制でコンテンツマーケティングを支援いたします。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>
                    </div>

                </div>



                <div className="p-solution">

                    <section className="p-solution-box">
                        <div className="p-solution-box-inner">

                            <div className="p-solution-box-cnt is-marketing">
                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--text">
                                    販促ソリューション事業
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    私たちはDX事業に加え、お客様の販売促進に係るソリューションを準備しております。DXツールにより業務効率化が図れたとしても、それを実務に乗せる為の施策や人員体制、運用ルールなどが整備されていなければ効果を期待することはできません。既存顧客の離脱、新規顧客の開拓、新製品の効率的な周知、売れない商品の分析・対策、他社と差別化を実施する為のイベントや広報など、DXツール以外で実施することが多く存在します。私たちは御社の業務全般を、販促ソリューションとDXツールという軸で総合的にご提案して参ります。
                                    </p>
                                </div>
                            </div>

                        </div>
                    </section>

                    <div className="p-solution-list is-sub">
                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    販売支援
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/analytics_support.png"
                                    alt="販売支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    自社PB製品の周知、OEM製品、戦略的拡販商品などを効率的に多くのお客様の目に触れさせなければなりません。どのタイミングで何を見ているのか？よく見るページやサイトはどこなのか？情報収集はどこから行っているのか？仮説・検証を繰り返し、「メディア」事業（以下）とも連携しながらご提案いたします。また独自「メディア」構築のご提案も可能です。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    営業支援
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/sales_support.png"
                                    alt="営業支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    既存顧客へのＤＸツールの周知及び説明会の開催。新規顧客開拓への支援及び代行等をご提案いたします。特にＤＸツールの利用を促す為のアクションなどにご活用いただけます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    イベント支援
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/event_support.png"
                                    alt="イベント支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    御社の事業計画をヒアリングの上、オフライン・オンラインイベントの企画/設計/運用に留まらず、イベント後の保守や年間計画スケジュールなどもご提案いたします。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">

                                <div className="p-solution-box-title">
                                    <h2 className="p-solution-box-title--sub">
                                    広報支援
                                    </h2>
                                </div>

                                <div className="p-solution-box-cnt__logo">
                                    <StaticImage
                                    src="../images/image/public_support.png"
                                    alt="広報支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-solution-box-cnt__icon"
                                    />
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    情報発信に係るすべてを当社で請負、御社の広報事業部として運用していきます。マスメディアやオンラインメディアとの繋がりを活かしご提案いたします。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.b-rock.jp/" title="デジタルマーケティング事業部" classTag="c-link--btn is-external" target="true">
                                    特設サイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>

                            </div>
                        </section>
                    </div>

                </div>

            </Contents>

        </>
    )

}

export default Marketing;


